<template>
  <div class="mainContainer container" style="">
    <div style="display:flex;">
      <div id="backArrow" class="backBtn"  @click="this.$root.gotoRoute({ path: '/settings' })"><font-awesome-icon :icon="['fas', 'arrow-left']" /></div>
      <h5 style="margin:auto;">Extend User Account</h5>
    </div>
    <hr>
    <div class="msg">
      Please upgrade your app first.
    </div>
  </div>
</template>

<script>



export default {
  name: 'ExtendUser',
  components: {

  },
  data(){
    return{

    }
  },
  methods:{

  },
  mounted() {
    document.title = "Extend User";
  }
}
</script>

<style scoped>
.msg{
  margin:50px 10px 10px 10px;
  padding:10px;
}



</style>